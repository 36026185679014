<template>
  <div class="progressdata-bars">
    <div class="round"></div>
    <div class="bar"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.progressdata-bars {
  width: 20px;
  height: 30px;
  /* background-color: #f3f3f3; */
  overflow: hidden;
  position: relative;
  /* border: 1px solid red; */
}
.round {
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #999;
}
.bar {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 12px;
  width: 1px;
  overflow: hidden;
  background-color: #999;
  animation: progress 3s linear infinite;
}

@keyframes progress {
  0% {
    height: 0;
  }
  50% {
    height: 12px;
  }
  100% {
    height: 0;
  }
}
</style>
