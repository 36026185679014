<template>
  <div class="counter" id="js-counter">
    <div class="counter_monitor">{{ counter }} 年</div>
  </div>
</template>

<script>
import { TweenMax, Linear } from "gsap";

export default {
  props: ["starts", "ends", "durations"],
  data() {
    return {
      counter: 0,
      start: 0,
      end: 100,
      duration: 3,
      format: true,
    };
  },

  computed: {
    formatter() {
      return new Intl.NumberFormat("en-IN");
    },
  },

  mounted() {
    // console.log("--", this.starts, this.ends, this.durations);
    this.start = this.starts;
    this.end = this.ends;
    this.duration = this.durations;
    this.clicks();
  },

  methods: {
    clicks() {
      TweenMax.to(this, this.duration, {
        ease: Linear.easeNone,
        log: this.end,
        onUpdate: () => {
          this.counter = this.format
            ? this.formatter.format(Math.floor(this.log))
            : Math.floor(this.log);
        },
      });

      TweenMax.set(this, {
        log: this.start,
      });
    },
  },
};
</script>

<style>
.counter_monitor {
  color: #7b6df7;
  font-size: 5rem;
}
input:focus {
  outline: none;
}
@media (max-width: 550px) {
  .counter_monitor {
    font-size: 32px;
  }
}
</style>
