export const setToken = (token) => {
  sessionStorage.setItem("TOKEN", token);
};
//   获取
export const getToken = () => {
  return sessionStorage.getItem("TOKEN");
};
//   移除
export const removeToken = () => {
  sessionStorage.removeItem("TOKEN");
};
