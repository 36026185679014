<template>
  <div class="hander">
    <div
      class="hander-center"
      :style="{
        background:
          imgstate && urldata == '/home'
            ? ' rgba(0, 0, 0, 0)'
            : 'rgb(255, 255, 255)',
        color: imgstate && urldata == '/home' ? 'aliceblue' : 'black',
      }"
    >
      <div class="container">
        <img class="handerimg" src="../img/bacimg.png" alt="" />
        <div class="div-content" @click="gohome">
          <div class="contenttitle">
            <img class="img" src="./mediacenter/style/logobig.png" alt="" />
          </div>
          <div class="contentcenter">西安紫云环保科技集团有限公司</div>
        </div>
      </div>
      <!-- <div class="hander-top">
        <img class="handerimg" src="../img/hander.png" alt="" />
      </div> -->
      <div class="hander-bottom">
        <div class="itemlist">
          <ul class="horizontal-list">
            <li
              v-for="(item, index) in itemlist"
              :key="index"
              @click="activelist(index)"
            >
              <div class="showdownitem" @click="purouter(item.name)">
                {{ item.name }}

                <div
                  :class="
                    activeIndex == index ? 'progress-bars' : 'progress-bar'
                  "
                ></div>
              </div>
            </li>
          </ul>
        </div>

        <div class="buyingproducts">
          <el-dropdown>
            <span class="el-dropdown-link">
              购买渠道
              <i style="font-size: 1.6rem" class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="firstbuy"
                >紫云1688旗舰店</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="breadpage" v-if="breadstate">
        <Breadcrumbs :datasurl="datasurl" />
      </div>
    </div>
    <div class="seekadvice" @click="seekadvice">
      <el-tooltip class="item" effect="dark" content="在线咨询" placement="top">
        <i style="font-size: 32px" class="el-icon-service"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "./breadcrumbs";
import throttle from "lodash/throttle";
export default {
  components: {
    Breadcrumbs,
  },
  props: ["pagetwoimg", "pagethreeing"],
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "产品一",
        },
        {
          value: "选项2",
          label: "产品二",
        },
        {
          value: "选项3",
          label: "产品三",
        },
      ],
      showSearch: false,
      activestate: -1,
      pageonetate: false,
      imgstate: true,
      targetDivHeight: 0, // 初始高度
      showlist: -1,
      showcenter: -1,
      centerstate: -1,
      itemlist: [
        {
          name: "首页",
          id: 1,
          imgs: [],
        },
        {
          name: "业务领域",
          id: 3,
          imgs: [],
        },
        {
          name: "产品中心",
          id: 4,
          imgs: [],
        },
        {
          name: "媒体中心",
          id: 5,
          imgs: [],
        },
        {
          name: "关于紫云",
          id: 2,
          imgs: [],
        },
        {
          name: "联系我们",
          id: 6,
          imgs: [],
        },
      ],
      activeIndex: -1,
      urldata: null,
      datasurl: [],
      urldatas: [
        "/home",
        "/business",
        "/product",
        "/mediacenter",
        "/introduction",
        "/contact",
      ],
      breadstate: false,
    };
  },
  mounted() {
    var currentUrl = window.location.href;
    this.urldata = currentUrl.split("#")[1];
    if (this.urldatas.includes(this.urldata)) {
      this.breadstate = false;
    } else {
      let data = this.urldata.split("/");
      if (data[data.length - 2] == "product") {
        this.datasurl = [
          {
            name: "首页",
            path: "/home",
          },
          {
            name: "产品中心",
            path: "/product",
          },
          {
            name: "详情信息",
            path: this.urldata,
          },
        ];
      } else if (data[data.length - 2] == "business") {
        this.datasurl = [
          {
            name: "首页",
            path: "/home",
          },
          {
            name: "产品中心",
            path: "/business",
          },
          {
            name: "详情信息",
            path: this.urldata,
          },
        ];
      } else if (data[data.length - 1] == "compontnews") {
        this.datasurl = [
          {
            name: "首页",
            path: "/home",
          },
          {
            name: "媒体中心",
            path: "/mediacenter",
          },
          {
            name: "详情数据",
            path: "/compontnews",
          },
        ];
      }
      this.breadstate = true;
    }
  },
  watch: {
    pagethreeing(val, old) {
      if (val) {
        this.imgstate = true;
      } else {
        this.imgstate = false;
      }
    },
    $route: function (to, from) {
      this.urldata = to.path;
      if (this.urldata == "/home") {
        this.imgstate = true;
      }
      if (this.urldatas.includes(this.urldata)) {
        this.breadstate = false;
      } else {
        let data = this.urldata.split("/");

        if (data[data.length - 2] == "product") {
          this.datasurl = [
            {
              name: "首页",
              path: "/home",
            },
            {
              name: "产品中心",
              path: "/product",
            },
            {
              name: "详情信息",
              path: this.urldata,
            },
          ];
        } else if (data[data.length - 2] == "business") {
          this.datasurl = [
            {
              name: "首页",
              path: "/home",
            },
            {
              name: "产品中心",
              path: "/business",
            },
            {
              name: "详情信息",
              path: this.urldata,
            },
          ];
        } else if (data[data.length - 1] == "compontnews") {
          this.datasurl = [
            {
              name: "首页",
              path: "/home",
            },
            {
              name: "媒体中心",
              path: "/mediacenter",
            },
            {
              name: "详情数据",
              path: "/compontnews",
            },
          ];
        }
        this.breadstate = true;
      }
    },
    pagetwoimg(val, old) {
      if (val) {
        this.imgstate = false;
        // this.imgstate = true;
        this.pageonetate = true;
      } else {
        this.pageonetate = false;
        this.imgstate = true;
      }
    },
  },
  methods: {
    gohome() {
      this.$router.push("/home");
    },
    seekadvice() {
      this.$router.push("/contact");
    },
    firstbuy() {
      window.open(
        "https://shop13p84u973s834.1688.com/page/index.html?spm=0.0.wp_pc_common_header_companyName_undefined.0"
      );
    },
    toggleSearch() {
      this.showSearch = !this.showSearch; // 切换搜索框的显示与隐藏
    },
    purouter(data) {
      if (data == "关于紫云") {
        this.$router.push("/introduction");
      } else if (data == "首页") {
        this.$router.push("/home");
      } else if (data == "业务领域") {
        this.$router.push("/business");
      } else if (data == "媒体中心") {
        this.$router.push("/mediacenter");
      } else if (data == "产品中心") {
        this.$router.push("/product");
      } else if (data == "联系我们") {
        this.$router.push("/contact");
      }
    },
    showhanderdown() {
      if (this.pagetwoimg == false) {
        this.imgstate = false;
      }
    },
    showhanderout() {
      if (this.pagetwoimg == false) {
        this.imgstate = true;
      }
    },
    showDropdown: throttle(function (index) {
      this.activeIndex = index - 1;
      this.showlist = index;
      this.showcente = index;
      this.targetDivHeight = 140;
    }),
    showDropout: throttle(function (index) {
      if (this.activestate == -1) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = this.activestate;
      }
      this.showlist = -1;
      if (this.showlist == -1 && this.showcente == -1) {
        this.targetDivHeight = 0;
      } else if (this.showlist == -1 && this.centerstate == -1) {
        this.targetDivHeight = 0;
      }
    }),
    showDropdowns: throttle(function (index) {
      if (index == 1) {
        this.activeIndex = this.showcente - 1;
      }
      this.centerstate = index;
      this.targetDivHeight = 140;
    }),
    showDropouts: throttle(function () {
      if (this.activestate == -1) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = this.activestate;
      }
      this.showcente = -1;
      this.centerstate = -1;
      this.targetDivHeight = 0;
    }),

    activelist(data) {
      this.activeIndex = data;
      this.activestate = data;
    },
  },
};
</script>

<style scoped>
.contentcenter {
  font-size: 22px;
  margin-top: 5px;
  font-weight: bold;
  margin-left: 11px;
  text-shadow: 2px 2px 4px rgb(141, 141, 141), -2px -2px 4px rgb(15, 15, 15);
}
.img {
  width: 8rem;
}
.div-content {
  position: absolute;
  left: 4vw;
  top: 10px;
  color: #fff;
  display: flex;
}
.div-content {
  cursor: pointer;
}
.pageoneimg {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 70px;
}
.container {
  background: rgb(0, 0, 0);
  height: 7.1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.hander-top {
  background: rgb(0, 0, 0);
  height: 7.1rem;
  display: flex;
  justify-content: space-between;
}
.handerimg {
  width: 100%;
  background-size: cover;
}
.el-icon-s-operation {
  font-size: 2.4rem;
}
.el-icon-menu {
  font-size: 2.4rem;
}

.caidan {
  font-size: 1.6rem;
}

.seekadvice {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 50px;
  right: 50px;
}
.el-icon-service:hover {
  color: #409eff;
}
.el-dropdown-link {
  cursor: pointer;
  font-size: 1.6rem;
  color: #de7d1c;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.itemlistdata {
  position: fixed;
  top: 138px;
  width: 100%;
  padding: 0 30px;
  background-color: #fff;
  overflow: hidden;
  transition: height 0.5s; /* 过渡效果的持续时间 */
}
.height-fade-enter-active,
.height-fade-leave-active {
  transition: height 0.5s;
}

.height-fade-enter, .height-fade-leave-to /* .height-fade-leave-active 在版本 2.1.8 中新增 */ {
  height: 0;
}
.hander-bottom {
  height: 6.6rem;
  padding: 0 3rem;
  display: flex;
  font-size: 1.6rem;
  border-bottom: 1px solid #ececec;
}
.breadpage {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 3rem;
  display: flex;
  font-size: 1.6rem;
  background: #fff;
  border-bottom: 1px solid #ececec;
  /* z-index: 1; */
}
.my-images {
  height: 7rem;
}
.imgsize {
  width: 18rem;
  height: 10rem;
  overflow: hidden;
}
.my-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
}
.my-image:hover {
  transform: scale(1.2);
}
.itemlistdatali {
  margin-top: 1rem;
  width: 18rem;
  height: 100%;
}
.itemlistname {
  height: 100%;
  text-align: center;
}
.hander-center {
  height: 13.8rem;
}
/* .hander-center:hover {
  color: black;
  background: rgb(255, 255, 255);
} */
.horizontal-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.horizontal-list li {
  margin-right: 1rem;
  line-height: 4.6rem;
}
li {
  position: relative;
  padding: 0.8rem;
  margin-bottom: 1rem;
}

/* 设置进度条的样式 */
.progress-bar {
  position: relative;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0.4rem;
  background-color: #6cccdc;
  transition: width 0.5s ease-in-out;
}
.progress-bars {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.4rem;
  background-color: #6cccdc;
  transition: width 0.5s ease-in-out;
}
/* 点击后的样式*/
li:visited .progress-bar {
  width: 100%;
}
li:hover .progress-bar {
  width: 100%;
}
.hander {
  display: flex;
  flex-direction: column;
}

.search {
  flex-basis: 30%;
}
.itemlist {
  flex-basis: 60%;
}
.buyingproducts {
  display: flex;
  flex-basis: 40%;
  align-items: center;
  justify-content: right;
}
@media (max-width: 950px) {
  .buyingproducts {
    flex-basis: 40%;
    margin-bottom: 18px;
  }
  .img {
    margin-top: 2px;
    width: 6rem;
  }
  .contentcenter {
    font-size: 18px;
    font-weight: bold;
    margin-left: 9px;
  }
  .el-icon-s-operation {
    font-size: 2rem;
  }
  .my-images {
    height: 5rem;
  }

  .caidan {
    font-size: 1.5rem;
  }
  .chanpin {
    font-size: 1.5rem;
  }
  .el-icon-menu {
    font-size: 2rem;
  }
  .el-icon-search {
    font-size: 2rem;
  }
  .el-icon-close {
    font-size: 2rem;
  }
  .hander-bottom {
    padding: 0 0.6rem;
  }
  .horizontal-list {
    width: 34rem;
  }
  .horizontal-list li {
    font-size: 1.2rem;
    padding: 0.1rem;
  }
  .el-dropdown-link {
    font-size: 1.2rem;
    margin-bottom: 18px;
  }
  .el-icon-arrow-down {
    display: none;
  }
  @media (max-width: 550px) {
    .breadpage {
      padding: 0 1rem;
    }
  }
}
</style>
