// 引入路由组件
import Home from "../pages/home/home.vue";
import Animation from "../pages/donghuas";
// import Zybiref from "../pages/zybrief/index.vue";
// import Business from "../pages/business";
// import Mediacenter from "../pages/mediacenter";
// import Product from "../pages/product";
// import Contact from "../pages/contact";
// import Businessarea from "../pages/businessarea";
// import Details from "../pages/product/page/details.vue";
// import Detailstwo from "../pages/product/page/detailstwo.vue";
// import Detailsthree from "../pages/product/page/detailsthree.vue";
// import Detailsfour from "../pages/product/page/detailsfour.vue";
// import Czzys from "../pages/business/pages/czzys.vue";
// import Czyys from "../pages/business/pages/czyys.vue";
// import Xyzys from "../pages/business/pages/xyzys.vue";
// import Yjyys from "../pages/business/pages/yjyys.vue";
// import Dbyys from "../pages/business/pages/dbyys.vue";

// import Gyjps from "../pages/business/pages/gyjps.vue";
// import Szws from "../pages/business/pages/szws.vue";
// import Czshws from "../pages/business/pages/czshws.vue";
// import Yshs from "../pages/business/pages/yshs.vue";
// import Zshy from "../pages/business/pages/zshy.vue";
// import Ylws from "../pages/business/pages/ylws.vue";
// import Zsszhzx from "../pages/business/pages/zsszhzx.vue";
// import Zhsw from "../pages/business/pages/zhsw.vue";
// import Hjzl from "../pages/business/pages/hjzl.vue";
// import Compontnews from "../pages/mediacenter/pages/companynews.vue";
export default [
  {
    path: "/home",
    component: Home,
    // component: () => import("../pages/home/home.vue"),
    // 控制该组件是否显示
  },
  {
    path: "/animation",
    // component: () => import("../pages/donghuas"),
    component: Animation,
  },
  {
    path: "/introduction",
    // component: Zybiref,
    component: () => import("../pages/zybrief/index.vue"),
    name: "Introduction",
    // meta: { showFooter: true }
    // 控制该组件是否显示
  },

  {
    path: "/compontnews",
    // component: Compontnews,
    component: () => import("../pages/mediacenter/pages/companynews.vue"),
    name: "Compontnews",
    // meta: { showFooter: true }
    // 控制该组件是否显示
  },
  {
    path: "/business",
    // component: Business,
    component: () => import("../pages/business"),
  },
  // {
  //   path: "/allocation",
  //   // component: Business,
  //   component: () => import("../pages/allocationimg/index.vue"),
  // },
  {
    path: "/business/czzys",
    // component: Czzys,
    component: () => import("../pages/business/pages/czzys.vue"),
  },
  {
    path: "/business/czyys",
    // component: Czyys,
    component: () => import("../pages/business/pages/czyys.vue"),
  },
  {
    path: "/business/xyzys",
    // component: Xyzys,
    component: () => import("../pages/business/pages/xyzys.vue"),
  },
  {
    path: "/business/yjyys",
    // component: Yjyys,
    component: () => import("../pages/business/pages/yjyys.vue"),
  },
  {
    path: "/business/dbyys",
    // component: Dbyys,
    component: () => import("../pages/business/pages/dbyys.vue"),
  },

  {
    path: "/business/gyjps",
    // component: Gyjps,
    component: () => import("../pages/business/pages/gyjps.vue"),
  },
  {
    path: "/business/szws",
    // component: Szws,
    component: () => import("../pages/business/pages/szws.vue"),
  },
  {
    path: "/business/czshws",
    // component: Czshws,
    component: () => import("../pages/business/pages/czshws.vue"),
  },
  {
    path: "/business/zshy",
    // component: Zshy,
    component: () => import("../pages/business/pages/zshy.vue"),
  },
  {
    path: "/business/ylws",
    // component: Ylws,
    component: () => import("../pages/business/pages/ylws.vue"),
  },
  {
    path: "/business/yshs",
    // component: Yshs,
    component: () => import("../pages/business/pages/yshs.vue"),
  },
  {
    path: "/business/zsszhzx",
    // component: Zsszhzx,
    component: () => import("../pages/business/pages/zsszhzx.vue"),
  },
  {
    path: "/business/zhsw",
    // component: Zhsw,
    component: () => import("../pages/business/pages/zhsw.vue"),
  },
  {
    path: "/business/hjzl",
    // component: Hjzl,
    component: () => import("../pages/business/pages/hjzl.vue"),
  },

  {
    path: "/product",
    component: () => import("../pages/product"),
    name: "Product",
  },
  {
    path: "/mediacenter",
    // component: Mediacenter,
    component: () => import("../pages/mediacenter"),
    name: "Mediacenter",
  },
  {
    path: "/contact",
    // component: Contact,
    component: () => import("../pages/contact"),
  },
  {
    path: "/businessarea",
    // component: Businessarea,
    component: () => import("../pages/businessarea"),
  },
  {
    path: "/product/details",
    // component: Details,
    component: () => import("../pages/product/page/details.vue"),
  },
  {
    path: "/product/detailstwo",
    // component: Detailstwo,
    component: () => import("../pages/product/page/detailstwo.vue"),
  },
  {
    path: "/product/detailsthree",
    // component: Detailsthree,
    component: () => import("../pages/product/page/detailsthree.vue"),
  },
  {
    path: "/product/detailsfour",
    // component: Detailsfour,
    component: () => import("../pages/product/page/detailsfour.vue"),
  },
  {
    path: "/product/detailsfive",
    // component: Detailsfour,
    component: () => import("../pages/product/page/detailsfive.vue"),
  },
  {
    path: "/product/detailsall",
    // component: Detailsfour,
    component: () => import("../pages/product/page/detailsall.vue"),
    name: "Detailsall",
  },
  {
    // 重定向，访问根（/）就重定向到首页
    path: "/",
    redirect: "/animation",
  },
];
