import {
  PerspectiveCamera,
  Scene,
  FogExp2,
  WebGLRenderer,
  Geometry,
  TextureLoader,
  Vector3,
  VertexColors,
  Points,
  Color,
  PointsMaterial,
  Group,
} from "three";
import JSONLoader from "../loader/JSONLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import TWEEN from "@tweenjs/tween.js";
import * as THREE from "three";
// import createGui from './createGui';
import rangeRandom from "../utils/rangeRandom";
import djsstate from "../djsstate";

const container = document.createElement("div");
container.id = "view";
const glist = new Array(6);
let camera,
  scene,
  renderer,
  particles,
  group,
  geometry,
  animationTween,
  aroundPoints,
  animationId;

// 场景初始化
export function setupScene() {
  // 初始化变量
  initVariable();

  // 初始变换粒子
  initMainParticles();

  // 初始化环境粒子
  initAroundParticles();

  // 初始化模型
  initModel();

  window.addEventListener("resize", onWindowResize, false);

  animate();

  return {
    particles,
  };
}

// 模型切换
export function changeModel(index) {
  // 模型动画
  geometry.vertices.forEach(function (vtx, i) {
    let twInstance = vtx.tweenvtx;
    if (!twInstance) {
      twInstance = new TWEEN.Tween(vtx);
    }
    const length = glist[index].vertices.length;
    const o = glist[index].vertices[i % length];
    twInstance.delay(1500 * Math.random());
    twInstance
      .to(
        {
          x: o.x,
          y: o.y,
          z: o.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.In)
      // .delay(delay * Math.random())
      .start();
  });
}

// 改变尺寸时
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

// 动画
function animate() {
  animationId = requestAnimationFrame(animate);
  render();
}
export function clearAnimation() {
  if (animationId !== null) {
    cancelAnimationFrame(animationId);
    animationId = null;
  }
}

// 每一帧渲染
function render() {
  camera.lookAt(scene.position);
  geometry.verticesNeedUpdate = true;
  geometry.colorsNeedUpdate = true;

  TWEEN.update();
  renderer.render(scene, camera);
}
// 暂停动画
export function pauseAnimation() {
  if (animationTween) {
    animationTween.stop();
  }
}
// 首次动画
export function firstAnimation() {
  const baseVal = -Math.PI * 0.6;

  return new Promise((resolve) => {
    group.rotation.y = baseVal;
    animationTween = new TWEEN.Tween(group.rotation);
    animationTween
      .to(
        {
          y: 0,
        },
        5000
      )
      .delay(200)
      .onComplete(() => {
        // 绑定鼠标动画
        document.addEventListener("mousemove", onDocumentMouseMove, false);

        // 自动切换到第一个模型
        changeModel(0);
        resolve(true);
      })
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .start();
  });
}

// 初始化变量
function initVariable() {
  document.body.appendChild(container);

  // 初始化相机
  camera = new PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    10000
  );
  camera.position.z = 750;
  // 初始化场景
  scene = new Scene();
  // 雾化
  scene.fog = new FogExp2(0x01050c, 0.00001);
  // scene.fog = new THREE.Fog(0x999999, 0.1, 200);
  group = new Group();

  // var axisHelper = new THREE.AxisHelper(2500);
  // scene.add(axisHelper);
  scene.add(group);

  // 初始化renderer
  renderer = new WebGLRenderer();
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  // renderer.setClearColor(0x05050c);
  container.appendChild(renderer.domElement);
}
function getdingdian(data) {
  return new Promise((resolve, reject) => {
    // const loader2 = new GLTFLoader();
    const MTLLoader = new FBXLoader(); //材质文件加载器
    let vertices = [];
    MTLLoader.load(
      `assets/${data}.fbx`,
      (gltf) => {
        // 获取该Mesh的几何体（Geometry）
        for (let i = 0; i < gltf.children.length; i++) {
          // if (i > 1) {

          const mesh = gltf.children[i];
          const geometry = mesh.geometry;
          if (geometry instanceof THREE.BufferGeometry) {
            // 获取顶点位置数据

            const positionAttribute = geometry.attributes.position;

            const verticesArray = Array.from(
              { length: positionAttribute.array.length / 3 },
              (_, i) => positionAttribute.array.slice(i * 3, i * 3 + 3)
            );
            // 将每个包含三个元素的数组转换为对象

            const verticesObjects = [];
            if (geometry.name == "文本") {
              for (let index = 0; index < verticesArray.length; index++) {
                const vertex = new Vector3();

                vertex.x = verticesArray[index][0] * djsstate.$data.pagesix;
                if (djsstate.$data.pagesix == 50) {
                  vertex.y =
                    verticesArray[index][1] * djsstate.$data.pagesix + 50;
                } else {
                  vertex.y =
                    verticesArray[index][1] * djsstate.$data.pagesix + 100;
                }
                vertex.z = verticesArray[index][2] * djsstate.$data.pagesix;
                verticesObjects.push(vertex);
              }
            } else {
              for (let index = 0; index < verticesArray.length; index++) {
                const vertex = new Vector3();

                vertex.x = verticesArray[index][0] * djsstate.$data.pagesix;
                vertex.y = verticesArray[index][1] * djsstate.$data.pagesix;
                vertex.z = verticesArray[index][2] * djsstate.$data.pagesix;
                verticesObjects.push(vertex);
              }
            }

            vertices = [...vertices, ...verticesObjects];
          }
          // }
        }
        gltf.vertices = vertices;
        resolve(gltf); // 当 load 完成后，使用 resolve 传递结果
      },
      undefined,
      reject
    ); // 如果出现错误，使用 reject 传递错误
  });
}
// 初始化模型
async function initModel() {
  let datas = await getdingdian("test");
  let earthdata = await getdingdian("diqiu");
  let logodata = await getdingdian("logo3");
  let jiadata = await getdingdian("jia");
  const loader = new JSONLoader();
  loader.load("assets/1game.json", function (geo, materials) {
    geo.vertices = earthdata.vertices;
    geo.center();
    geo.normalize();
    geo.scale(350, 350, 350);
    geo.rotateX(Math.PI / 2); // 上下
    geo.rotateY(1.4 * Math.PI); // 左右
    geo.rotateZ(-Math.PI);
    glist[0] = geo;
  });
  loader.load("assets/1game.json", function (geo, materials) {
    geo.vertices = logodata.vertices;
    geo.center();
    geo.normalize();
    if (djsstate.$data.pagesix == 100) {
      geo.scale(550, 500, 500);
      // geo.rotateZ(-Math.PI / 24);
      // geo.rotateX(-Math.PI / 4);
      geo.rotateY(-Math.PI / 20); // 左右
      geo.translate(-300, 0, 0);
    } else {
      geo.scale(300, 250, 250);
      // geo.rotateZ(-Math.PI / 24);
      // geo.rotateX(-Math.PI / 4);
      geo.rotateY(-Math.PI / 20); // 左右
      geo.translate(-50, 0, 0);
    }

    glist[1] = geo;
  });
  loader.load("assets/2ac.json", function (geo, materials) {
    geo.vertices = datas.vertices;
    geo.center();
    geo.normalize();
    geo.rotateX(Math.PI / 2); // 上下
    geo.rotateY(-1.1 * Math.PI); // 左右
    geo.rotateZ(Math.PI); // 上下
    if (djsstate.$data.pagesix == 100) {
      geo.scale(450, 450, 450);
      // geo.rotateX(-Math.PI); // 上下
      geo.translate(350, 0, 0);
    } else {
      geo.scale(400, 400, 400);
      geo.translate(150, 0, 0);
    }

    glist[2] = geo;
  });
  loader.load("assets/3book.json", function (geo, materials) {
    geo.vertices = jiadata.vertices;
    geo.center();
    geo.normalize();
    geo.rotateX(Math.PI / 2); // 上下
    geo.rotateZ(Math.PI); // 上下
    geo.rotateY(-Math.PI / 4); // 左右
    if (djsstate.$data.pagesix == 100) {
      geo.scale(550, 550, 550);
      geo.translate(-300, 0, 0);
    } else {
      geo.scale(400, 400, 400);
      geo.translate(-100, 0, 0);
    }

    // geo.rotateY(-Math.PI / 10); // 左右
    glist[3] = geo;
  });

  loader.load("assets/4movice.json", function (geo, materials) {
    geo.center();
    geo.normalize();
    if (djsstate.$data.pagesix == 100) {
      geo.scale(900, 900, 900);
    } else {
      geo.scale(450, 450, 450);
    }
    geo.rotateX(Math.PI / 2);
    geo.rotateY(0.98 * Math.PI); // 左右

    glist[4] = geo;
  });

  loader.load("assets/5kv.json", function (geo, materials) {
    geo.center();
    geo.normalize();
    geo.scale(800, 800, 800);
    geo.translate(0, -400, 0);
    glist[5] = geo;
  });
  let wenzidata = await getdingdian("wenzi");
  glist[6] = wenzidata;
  // loader.load("assets/qr.json", function (geo, materials) {
  // geo.vertices = wenzidata.vertices;
  // geo.center();
  // geo.normalize();
  // console.log("  djsstate.$data.pagenum", djsstate.$data.pagenum);
  // geo.scale(
  //   djsstate.$data.pagenum,
  //   djsstate.$data.pagenum,
  //   djsstate.$data.pagenum
  // );
  // glist[5] = geo;
  // });
}
// 清除粒子动画
export function clearMainParticles() {
  if (particles) {
    group.remove(particles);
    particles = null;
  }
}
// 初始化变换粒子
function initMainParticles() {
  // 初始化geometry
  geometry = new Geometry();

  // 初始化贴图
  const textureLoader = new TextureLoader();
  //解决跨域问题
  textureLoader.crossOrigin = "";
  // 圆点
  const mapDot = textureLoader.load("/assets/gradient.png");

  const POINT_LENGTH = 15000,
    POINT_SIZE = 4;
  for (let i = 0; i < POINT_LENGTH; i++) {
    const vertex = new Vector3();
    vertex.x = rangeRandom(window.innerWidth, -window.innerWidth);
    vertex.y = rangeRandom(window.innerHeight, -window.innerHeight);
    vertex.z = rangeRandom(window.innerWidth, -window.innerWidth);
    geometry.vertices.push(vertex);
    geometry.colors.push(new Color(255, 255, 255));
  }
  const material = new PointsMaterial({
    size: POINT_SIZE,
    sizeAttenuation: true,
    color: 0xffffff,
    transparent: true,
    opacity: 1,
    map: mapDot,
  });
  material.vertexColors = true;
  // material.vertexColors = VertexColors;
  particles = new Points(geometry, material);

  group.add(particles);
  // scene.add(particles);
}
// 清除环境粒子
export function clearAroundParticles() {
  if (aroundPoints) {
    // 从group中移除aroundPoints
    group.remove(aroundPoints);

    // 设置aroundPoints为null，释放引用
    aroundPoints = null;
  }
}

// 初始化环境粒子
function initAroundParticles() {
  const around = new Geometry();

  // 初始化贴图
  const textureLoader = new TextureLoader();
  // 解决跨域问题
  textureLoader.crossOrigin = "";
  // 圆点
  const mapDot = textureLoader.load("/assets/gradient.png");

  const minZVal = window.innerWidth * 0.7;
  const minYVal = window.innerHeight * 0.8;
  const color = new Color(255, 255, 255);
  // 初始化漫游粒子
  for (let i = 0; i < 200; i++) {
    const vertex = new Vector3();
    vertex.x = rangeRandom(minZVal, -minZVal); // 水平方向
    vertex.y = rangeRandom(minYVal, -minYVal); // 垂直方向
    vertex.z = rangeRandom(minZVal, -minZVal);
    around.vertices.push(vertex);
    around.colors.push(color);
  }
  const aroundMaterial = new PointsMaterial({
    size: 10,
    sizeAttenuation: true,
    color: 0xffffff,
    transparent: true,
    opacity: 1,
    map: mapDot,
  });

  aroundMaterial.vertexColors = VertexColors;
  aroundPoints = new Points(around, aroundMaterial);
  group.add(aroundPoints);
  // scene.add(aroundPoints);

  new TWEEN.Tween(aroundPoints.rotation)
    .to(
      {
        y: Math.PI * 2,
      },
      200000
    )
    .repeat(Infinity)
    .start();
}

function onDocumentMouseMove(event) {
  const rotateY = (event.pageX / (window.innerWidth * 30)) * 2 * Math.PI;
  const rotateX = (event.pageY / (window.innerHeight * 200)) * 2 * Math.PI;

  new TWEEN.Tween(group.rotation)
    .to(
      {
        x: rotateX,
        y: rotateY,
      },
      3000
    )
    .easing(TWEEN.Easing.Quadratic.Out)
    .start();

  event.preventDefault();
}
// export function getnum(data) {
//   djsstate.$data.pagenum = data;
// }
