// 对于 axios 进行二次封装

import axios from "axios";
import store from "../store";
import { getToken } from "../utils/token";
const request = axios.create({
  // 配置对象
  baseURL: "/api", // 基础路径，发请求时，路径当中会出现 api
  timeout: 5000,
});
request.interceptors.request.use((config) => {
  // let token = getToken();
  // if (token != null) {
  //   // config.headers.Authorization = "Bearer " + store.state.user.Authorization;
  //   config.headers.Authorization = "Bearer " + token;
  // }
  return config;
});
request.interceptors.response.use((res) => {
  return res;
});
export default request;
