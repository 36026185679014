import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router/index.js";
import Carousel3d from "vue-carousel-3d";
import store from "./store";
Vue.use(Carousel3d);
import dataV from "@jiaminghi/data-view";
import VueLazyload from "vue-lazyload";
const loadimage = require("./img/loading.png");
Vue.use(VueLazyload, {
  preLoad: 1.3, // 预加载高度比例
  error: loadimage, // 加载失败时显示的占位图
  // loading: "./img/loading.png", // 加载中显示的占位图
  attempt: 1, // 尝试加载次数
});
Vue.use(dataV);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$bus = new Vue();
new Vue({
  render: (h) => h(App),
  router,
  store, //注册仓库
}).$mount("#app");
