import Vue from "vue";
const globalInstance = new Vue({
  data: {
    pagesix: 100,
  },

  methods: {
    updateGlobalData(newData) {
      this.pagesix = newData;
    },
  },
});
export default globalInstance;
