import request from "./request";
export function geilist(data) {
  return request({
    url: `/article?subChannelId=8&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
export function geindustry(data) {
  return request({
    url: `/article?subChannelId=9&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
export function getvideo(data) {
  return request({
    url: `/article?subChannelId=139&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
// 专利
export function gethonor(data) {
  return request({
    url: `/article?subChannelId=189&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
// 荣誉
export function getpatent(data) {
  return request({
    url: `/article?subChannelId=190&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
//生产基地
export function getproduction(data) {
  return request({
    url: `/article?subChannelId=140&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
//招募
export function getrecruit(data) {
  return request({
    url: `/article?subChannelId=141&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
export function reqlogin(data) {
  return request({
    url: `/auth/jwt/login`,
    method: "post",
    data: data,
  });
}
export function loginout(data) {
  return request({
    url: `/auth/jwt/logout`,
    method: "post",
    data: data,
  });
}
export function getkeys() {
  return request({
    url: `/env/client-public-key`,
    method: "get",
  });
}
export function getnewdata(id) {
  return request({
    url: "article/" + id,
    method: "get",
  });
}
//公司历程
export function getcourse(data) {
  return request({
    url: `/article/event?subChannelId=8`,
    method: "get",
  });
}
//产品数据
export function getproduct(data) {
  return request({
    url: `/article?subChannelId=240&page=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "get",
  });
}
//详情信息
export function proddetails(data) {
  return request({
    url: `/article/` + data,
    method: "get",
  });
}
export function getnewtoken(data) {
  return request({
    url: `/auth/jwt/refresh-token`,
    method: "post",
    data: data,
  });
}
export function getpageimg() {
  return request({
    url: `/block-item?blockId=1&isEnabled=true`,
    method: "get",
  });
}