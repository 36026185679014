import Vue from "vue";
const globalInstance = new Vue({
  data: {
    globalData: false,
  },
  methods: {
    updateGlobalData(newData) {
      this.globalData = newData;
    },
  },
});
export default globalInstance;
