<template>
  <div class="pagefive">
    <div class="pagefive_center">
      <div class="pagefivetitle">新闻资讯</div>
      <div
        class="pagefivebtn"
        :style="{ transform: `translateX(-${num * sizenum}vw)` }"
      >
        <!-- :style="{
            'margin-left': num * 32 + 'vw',
            transition: 'all .3s ease-out .1s',
          }" -->
        <div
          class="pagefivechild"
          v-for="(item, index) in listdata"
          :key="index"
          @click="newdetails(item.id)"
        >
          <div>
            <!-- <img
              class="pagefiveimg"
              :src="require(`../mediacenter/style/${item.url}.jpeg`)"
              alt=""
            /> -->
            <img class="pagefiveimg" v-lazy="item.url" />
            <!-- <img class="pagefiveimg" :src="item.url" alt="" /> -->
          </div>
          <div class="time">{{ item.time }}</div>
          <div class="title">
            {{ item.title }}
          </div>
          <div class="center">
            {{ item.center }}
          </div>
          <div class="url" @click="newdetails(item.id)">
            <el-button
              size="small"
              icon="el-icon-right"
              style="background: #e1e1e1"
              circle
            ></el-button>
            了解更多
          </div>
        </div>
      </div>
      <div class="pagefiveoperate">
        <el-button
          :style="{ width: btnwidth + 'px', height: btnheight + 'px' }"
          icon="el-icon-back"
          @click="toleft"
          circle
        ></el-button>
        <el-button
          :style="{ width: btnwidth + 'px', height: btnheight + 'px' }"
          icon="el-icon-right"
          style="width: 52px; height: 52px"
          @click="toright"
          circle
        ></el-button>
        <el-button
          icon="el-icon-s-grid"
          style="border-radius: 30px"
          @click="gomediacenter"
        >
          更多咨询</el-button
        >
      </div>
    </div>
    <div class="progress">
      <Progress />
      <div class="progressitem">滑动</div>
    </div>
  </div>
</template>

<script>
import { geindustry } from "../../api/index";
import Progress from "../component/progress";
import { getToken } from "../../utils/token";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      queryParams: {
        pageSize: 6,
        pageNum: 1,
      },
      btnwidth: 52,
      btnheight: 52,
      sizenum: 32,
      screenWidth: window.innerWidth,
      num: 0,
      listdata: [
        // {
        //   time: "2022.08.07",
        //   title:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   center:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！ | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   url: "1",
        // },
        // {
        //   time: "2022.08.08",
        //   title:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   center:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！ | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   url: "2",
        // },
        // {
        //   time: "2022.08.09",
        //   title:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   center:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！ | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   url: "3",
        // },
        // {
        //   time: "2022.08.10",
        //   title:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   center:
        //     "命运与共，守望相助 | 紫云向汤加灾区援助净水设备助力解决饮用水问题！ | 紫云向汤加灾区援助净水设备助力解决饮用水问题！",
        //   url: "4",
        // },
      ],
    };
  },
  computed: {
    pagefivedata() {
      return this.$store.state.user.pagefivedata;
    },
  },
  watch: {
    pagefivedata(newVal, oldVal) {
      let newdatas = _.cloneDeep(this.$store.state.user.pagefivedata);
      newdatas.forEach((item) => {
        this.listdata.push({
          time: this.gettimedata(item.publishDate),
          title: item.title,
          center: item.seoDescription,
          url: item.image,
          id: item.id,
        });
      });
    },
  },
  mounted() {
    // let token = getToken();
    this.getlist();
    // if (token !== null) {
    //   this.getlist();
    // }

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    newdetails(data) {
      this.$router.push({
        name: "Compontnews",
        params: { xuzx: data },
      });
    },
    getlist() {
      geindustry(this.queryParams).then((result) => {
        let newdatas = result.data.content;
        newdatas.forEach((item) => {
          this.listdata.push({
            time: this.gettimedata(item.publishDate),
            title: item.title,
            center: item.seoDescription,
            url: item.image,
            id: item.id,
          });
        });
      });
    },
    gettimedata(data) {
      var date = new Date(data);

      var year = date.getFullYear(); // 获取年份
      var month = date.getMonth() + 1; // 获取月份（注意，月份是从0开始的，所以需要加1）
      var day = date.getDate(); // 获取日期
      return (
        year + "年" + this.padZero(month) + "月" + this.padZero(day) + "日"
      );
    },
    padZero(number) {
      return number < 10 ? "0" + number : number;
    },
    gomediacenter() {
      this.$router.push("/mediacenter");
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 900 && this.screenWidth > 550) {
        this.sizenum = 45;
      } else if (this.screenWidth < 550) {
        this.btnwidth = 40;
        this.btnheight = 40;
        this.sizenum = 90;
      } else {
        this.sizenum = 30;
      }
    },
    toleft() {
      if (this.num > 0) {
        this.num--;
      }
    },
    toright() {
      if (this.screenWidth > 900) {
        if (this.num < this.listdata.length - 3) {
          this.num++;
        }
      } else if (this.screenWidth > 550 && this.screenWidth < 900) {
        if (this.num < this.listdata.length - 2) {
          this.num++;
        }
      } else if (this.screenWidth < 550) {
        if (this.num < this.listdata.length - 1) {
          this.num++;
        }
      }
    },
  },
};
</script>

<style scoped>
.pagefiveimg {
  width: 100%;
  height: 200px;
  background-size: cover;
}
.pageone {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.progress {
  height: 60px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.progressitem {
  font-size: 14px;
  color: #999;
}
.pagefiveoperate {
  /* border: 1px solid red; */

  margin-top: 5%;
}
.pagefivetitle {
  font-size: 30px;
  font-weight: bold;
  /* text-align: center; */
  color: #333;
  margin-bottom: 5vh;
}
.pagefivechild .time {
  font-size: 30px;
  margin-bottom: 15px;
  color: #333;
}
.pagefivechild .title {
  font-size: 24px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2c3441;
}
.pagefivechild .center {
  font-size: 14px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
}
.pagefivechild .url {
  font-size: 14px;
  color: #2c3441;
}
.pagefivechild:hover {
  cursor: pointer;
}
.pagefive {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.pagefive_center {
  position: relative;
  overflow: hidden;
  left: 5%;
  margin-right: 5%;
  top: 20%;
  width: 90vw;
}
.pagefivebtn {
  display: flex;
  transition: transform 0.3s ease;
}

.pagefivechild {
  flex: 0 0 26vw;
  margin: 0 2vw; /* 可选：添加项目间的间距 */
}

/* 第一个子div左边距为0，第三个子div右边距为0 */
/* .pagefivechild:first-child {
  margin-left: 0;
}

.pagefivechild:last-child {
  margin-right: 0;
} */
@media (max-width: 900px) {
  .pagefivetitle {
    font-size: 24px;
  }
  .pagefivechild .time {
    font-size: 24px;
  }
  .pagefivechild .title {
    font-size: 18px;
  }

  .pagefivechild {
    flex: 0 0 41vw;
    margin: 0 2vw; /* 可选：添加项目间的间距 */
  }
  .pagefiveoperate {
    /* border: 1px solid red; */

    margin-top: 2%;
  }
  .pagefiveimg {
    width: 100%;
    height: 200px;
    background-size: cover;
  }
}
@media (max-width: 550px) {
  .pagefiveimg {
    width: 100%;
    height: 20vh;
    background-size: cover;
  }
  .pagefivetitle {
    font-size: 24px;
    font-weight: bold;
    /* text-align: center; */
    color: #333;
    margin-bottom: 5vh;
  }
  .pagefivechild {
    flex: 0 0 88vw;
    margin: 0 1vw;
  }

  .pagefivechild .time {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .pagefivechild .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
</style>
