<template>
  <div class="pagetwos">
    <!-- :style="{
        'background-image': pageimg ? `url(${require('../img/29.jpeg')})` : '',
        transform: pageimg ? 'scale(1.2)' : 'scale(1)',
        transition: pageimg ? 'transform 0.5s ease' : '',
      }" -->
    <div class="center">
      <div class="centertitle">紫云简介</div>
      <div class="centercontent">
        紫云集团成立于1993年，是一家以科技研发为龙头、以技术创新为中心，集生产制造，工程设计与施工为一体的高科技环保公司。
      </div>
      <div class="centerurl" @click="gohistory">
        <el-button
          class="lefticon"
          icon="el-icon-right"
          style="background: #6cccdc"
          circle
        ></el-button>
        了解更多
      </div>
    </div>
    <div class="centerbottom">
      <div class="child">
        <div class="childnum">获得多项</div>
        <div class="childnumname">国内外专利</div>
      </div>
      <div class="child">
        <div class="childnum">技术服务覆盖</div>
        <div class="childnumname">全国多个省市</div>
      </div>
      <!-- <div class="child">
        <div class="childnum">30+</div>
        <div class="childnumname">技术服务覆盖(省)</div>
      </div>
      <div class="child"></div> -->
      <!-- <div class="child">
        <div class="childnum">10000+</div>
        <div class="childnumname">工程服务经历(次)</div>
      </div>
      <div class="child">
        <div class="childnum">30万+</div>
        <div class="childnumname">累计服务家庭用户(个)</div>
      </div> -->
    </div>
    <div class="pagetwo">
      <div class="waterdroplet">
        <!--   :style="{
            width: pageWidth + 'vw',
            opacity: pageopacity,
          }" -->

        <img class="waterimg" v-lazy="page1" alt="" />
      </div>
    </div>
    <div class="progress">
      <Progress />
      <div class="progressitem">滑动</div>
    </div>
    <div class="centerright">
      <div class="vertical-text">不忘初心牢记使命</div>
      <div class="vertical-texts">绿水青山就是金山银山</div>
    </div>
  </div>
</template>

<script>
import Progress from "../component/progress";
export default {
  components: {
    Progress,
  },
  props: ["pagetwostate", "pagetwoimg"],
  data() {
    return {
      page1: "textures/29.png",
      pageimg: null,
      pagebac: 0,
      pageopacity: 1,
      pageWidth: 24,
      pagestate: null,
    };
  },
  watch: {
    pagetwoimg(val, old) {
      this.pageimg = val;
    },
    pagetwostate(val, old) {
      this.pagestate = val;
      if (this.pagestate) {
        this.pageWidth = 50;
        this.pageopacity = 0;
        this.pagebac = 1;
      } else {
        this.pageWidth = 24;
        this.pageopacity = 1;
        this.pagebac = 0;
      }
    },
  },
  mounted() {
    this.pageimg = this.pagetwoimg;
    this.pagestate = this.pagetwostate;
  },
  methods: {
    gohistory() {
      this.$router.push("/introduction");
    },
  },
};
</script>

<style scoped>
.childnumname {
  font-size: 1.6rem;
}
.centerright {
  width: 470px;
  height: 580px;
  position: absolute;
  left: 80%;
  top: 30%;
  display: flex;
}
.vertical-texts {
  margin-top: 150px;
  margin-left: 10px;
  font-size: 25px;
  writing-mode: vertical-rl;
  font-family: "华文宋体", serif;
  font-weight: bold;
  color: #fff;
}
.vertical-text {
  font-size: 30px;
  writing-mode: vertical-rl;
  font-family: "华文宋体", serif;
  font-weight: bold;
  color: #fff;
}
.progress {
  height: 60px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.progressitem {
  font-size: 14px;
  /* color: #999; */
  color: rgb(255, 255, 255);
}
.centerbottom {
  display: flex;
  justify-content: space-between;
  width: 470px;
  height: 580px;
  position: absolute;
  left: 5%;
  top: 80%;
  z-index: 10;
}
.child {
  flex: 1;
  /* color: #2c3441; */
  color: #fff;
}
.childnum {
  color: #6cccdc;
  font-size: 38px;
}
.centertitle {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  /* color: #333; */
  color: #fff;
}
.centercontent {
  font-size: 30px;
  margin-bottom: 20px;
  /* color: #2c3441; */
  color: #fff;
  font-weight: bold;
}
.centerurl {
  font-size: 18px;
  /* color: #2c3441; */
  color: #fff;
}
.centerurl:hover {
  cursor: pointer;
}
.center {
  z-index: 2;
  width: 600px;
  height: 250px;
  position: absolute;
  top: 30%;
  left: 5%;
}
.pagetwos {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}
.pagetwo {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background: url("../img/29.jpeg");
 
  transition: scale(1.2); */
}

.waterdroplet {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.waterimg {
  width: 450px;
  height: auto;
  transition: width 1s ease-in-out, height 1s ease-in-out,
    opacity 1.5s ease-in-out;
}
@media (max-width: 900px) {
  .vertical-text {
    font-size: 25px;
  }
  .vertical-texts {
    font-size: 18px;
  }
  .centercontent {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .center {
    width: 400px;
    height: 180px;
  }
}
@media (max-width: 550px) {
  .waterimg {
    width: 100vw;
  }
  .childnumname {
    font-size: 1rem;
  }
  .vertical-text {
    font-size: 20px;
  }
  .vertical-texts {
    font-size: 14px;
    margin-top: 100px;
  }
  .centercontent {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .center {
    width: 250px;
    height: 200px;
  }
  .centerbottom {
    display: flex;
    justify-content: space-between;
    width: 370px;
    height: 380px;
    position: absolute;
    left: 5%;
    top: 70%;
  }
  .childnum {
    font-size: 28px;
  }
}
</style>
