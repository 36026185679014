<template>
  <div class="footer">
    <!-- <div class="footertop">
      <img class="img" src="./mediacenter/style/logobig.png" alt="" />
      <div class="imgname">紫云集团</div>
    </div> -->

    <div class="footercenter">
      <div class="footertitle">
        <div class="footeritemcent" v-for="(item, index) in data" :key="index">
          <div class="titlename" @click="details(item.name)">
            {{ item.name }}
          </div>
          <div
            class="titleitem"
            v-for="(items, indexs) in item.children"
            :key="indexs"
            @click="details(items)"
          >
            {{ items }}
          </div>
        </div>
      </div>
      <div class="footercentericon">
        <div class="gywm">关于我们</div>
        <div class="centerpng">
          <img
            v-if="selecticon != 'selone'"
            class="imgpng"
            @mouseover="showhanderdown('1')"
            src="./mediacenter/style/weibo.png"
            alt=""
          />
          <img
            v-if="selecticon == 'selone'"
            class="imgpng"
            @mouseout="showhanderout()"
            src="./mediacenter/style/selweibo.png"
            alt=""
          />
          <img
            v-if="selecticon != 'seltwo'"
            class="imgpng"
            @mouseover="showhanderdown('2')"
            src="./mediacenter/style/weixin.png"
            alt=""
          />
          <img
            v-if="selecticon == 'seltwo'"
            class="imgpng"
            @mouseout="showhanderout()"
            src="./mediacenter/style/selweixin.png"
            alt=""
          />
          <img
            class="imgpng"
            v-if="selecticon != 'selthree'"
            @mouseover="showhanderdown('3')"
            src="./mediacenter/style/toutiao.png"
            alt=""
          />
          <img
            v-if="selecticon == 'selthree'"
            class="imgpng"
            @mouseout="showhanderout()"
            src="./mediacenter/style/seltoutiao.png"
            alt=""
          />
          <!-- <img
            v-if="selecticon != 'selfour'"
            class="imgpng"
            @mouseover="showhanderdown('4')"
            src="./mediacenter/style/kuaishou.png"
            alt=""
          />
          <img
            v-if="selecticon == 'selfour'"
            class="imgpng"
            @mouseout="showhanderout()"
            src="./mediacenter/style/selkuaishou.png"
            alt=""
          /> -->
          <img
            v-if="selecticon != 'selfive'"
            class="imgpng"
            @mouseover="showhanderdown('5')"
            src="./mediacenter/style/douyin.png"
            alt=""
          />
          <img
            v-if="selecticon == 'selfive'"
            class="imgpng"
            @mouseout="showhanderout()"
            src="./mediacenter/style/seldouyin.png"
            alt=""
          />
        </div>
        <div>
          <img v-if="selecticon == 'selone'" class="iconimgs" src="../img/weibos.png" alt="" />
          <img v-if="selecticon == 'seltwo'" class="iconimgs" src="../img/weixins.png" alt="" />
          <img v-if="selecticon == 'selthree'" class="iconimgs" src="../img/toutiaos.png" alt="" />
          <img v-if="selecticon == 'selfive'" class="iconimgs" src="../img/douyins.png" alt="" />
        </div>
      </div>
    </div>
    <div class="earthdata">
      <div class="lxdh">联系电话： 029-88333333 销售电话：18209224477 售后热线：15249224477</div>
      <div class="lxdh">地址：西安市高新区丈八一路1号汇鑫IBC C座七层702室</div>
    </div>

    <div class="footerbott">
      <div class="bqsy">
        Copyright 2006-2014 www.p8c.com All rights reserved. 西安紫云环保科技集团有限公司 版权所有
      </div>
      <img src="./mediacenter/style/beian.png" alt="" />
      <div class="sxwa" @click="queryba">陕公网安备 61019002002195号</div>
      <div class="sxip" @click="queryip">陕ICP备 19025489号</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          name: '关于紫云',
          children: ['关于紫云', '专利技术', '发展历程', '招募英才'],
        },
        {
          name: '行业应用',
          children: ['净水供给', '污水处理'],
        },
        {
          name: '产品中心',
          children: ['家用产品', '商用产品', '工业产品'],
        },
        {
          name: '服务中心',
          children: ['1688旗舰店'],
        },
        {
          name: '媒体中心',
          children: ['行业动态', '公司新闻', '企业视频'],
        },
      ],
      selecticon: 'selone',
      selectdata: null,
    }
  },
  methods: {
    queryba() {
      window.open('https://beian.mps.gov.cn/#/query/webSearch?polnm=61019002002195')
    },
    queryip(){
       window.open('https://beian.miit.gov.cn/')
    },
    showhanderdown(data) {
      if (data == '1') {
        this.selecticon = 'selone'
      } else if (data == '2') {
        this.selecticon = 'seltwo'
      } else if (data == '3') {
        this.selecticon = 'selthree'
      } else if (data == '4') {
        this.selecticon = 'selfour'
      } else if (data == '5') {
        this.selecticon = 'selfive'
      }
    },
    showhanderout() {
      // this.selecticon = "selone";
    },
    details(data) {
      if (data == '关于紫云' || data == '专利技术' || data == '发展历程' || data == '招募英才') {
        this.$router.push({
          name: 'Introduction',
          params: { regarding: data },
        })
      } else if (data == '行业应用' || data == '净水供给' || data == '污水处理') {
        this.$router.push('/business')
      } else if (
        data == '产品中心' ||
        data == '家用产品' ||
        data == '商用产品' ||
        data == '工业产品'
      ) {
        this.$router.push({
          name: 'Product',
          params: { Product: data },
        })
      } else if (data == '服务中心' || data == '1688旗舰店') {
        window.open(
          'https://shop13p84u973s834.1688.com/page/index.html?spm=0.0.wp_pc_common_header_companyName_undefined.0'
        )
      } else if (
        data == '媒体中心' ||
        data == '行业动态' ||
        data == '公司新闻' ||
        data == '企业视频'
      ) {
        this.$router.push({
          name: 'Mediacenter',
          params: { mediacenter: data },
        })
      }
    },
  },
}
</script>

<style scoped>
.sxip{
  font-size: 1.5rem;
  color: black;
  margin-left: 0.5rem;
}
.sxip:hover {
  color: #1890ff;
  cursor: pointer;
}
.sxwa {
  font-size: 1.5rem;
  color: black;
}
.sxwa:hover {
  color: #1890ff;
  cursor: pointer;
}
.bqsy {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  color: black;
}
.lxdh {
  font-size: 1.5rem;
  color: black;
}
.gywm {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom: 1.3rem;
  color: #333;
  font-weight: bold;
}
.iconimgs {
  width: 100px;
  height: 100px;
  margin-top: 5px;
}
.earthdata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imgpng {
  margin-left: 1rem;
}
.imgpng:first-child {
  margin-left: 0;
}
.centerpng {
  display: flex;
}
.footeritemcent {
  flex: 1;
  margin-left: 2rem;
}
.footeritemcent:first-child {
  margin-left: 0;
}
.titleitem {
  color: #595757;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}
.titlename {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom: 1.3rem;
  color: #333;
  font-weight: bold;
}
.footertitle {
  flex: 1;
  display: flex;
}
.footercentericon {
  width: 20%;
}
.footercenter {
  margin-top: 30px;
  padding: 2rem 6rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
}
.footerbott {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  border-top: 1px solid rgb(167, 167, 167);
  margin-bottom: 30px;
  /* padding: 5px; */
}
.footertop {
  padding: 2rem 6rem;
  display: flex;
}
.img {
  width: 10rem;
}
.imgname {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  font-size: 3rem;
  font-weight: bold;
  color: black;
}
.footer {
  width: 100%;
  background: #f2f2f2;
  /* background: #fff; */
  justify-content: space-between;
  border-top: 1px solid #ececec;
}
@media (max-width: 650px) {
  .sxwa {
    font-size: 1.2rem;
  }
  .bqsy {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    color: black;
  }
  .lxdh {
    font-size: 1.2rem;
    color: black;
    text-align: center;
  }
  .footercenter {
    display: flex;
    flex-direction: column;
  }
  .gywm {
    font-size: 1.2rem;
  }
  .footercenter {
    padding: 1rem 2rem;
  }
  .titlename {
    font-size: 1.2rem;
  }
  .titleitem {
    font-size: 1.2rem;
  }
  .footeritemcent {
    flex: 1;
    margin-left: 1rem;
  }
}
</style>
