<template>
  <div class="bread">
    <div class="toprouter">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, index) in datasurl"
          :key="index"
          :to="{ path: `${item.path}` }"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="djs" v-if="statedsj" @click="djsrouter">
      <i class="el-icon-back"></i>
      <div class="gslc">公司历程</div>
    </div>
  </div>
</template>

<script>
import globalInstance from "./djsstate";
export default {
  props: ["datasurl"],
  created() {},
  mounted() {
    this.statedsj = globalInstance.$data.globalData;
  },
  data() {
    return {
      data: [],
      statedsj: null,
    };
  },
  watch: {},
  methods: {
    djsrouter() {
      this.$router.push({
        name: "Introduction",
        params: { regarding: "发展历程" },
      });
    },
  },
};
</script>

<style scoped>
.gslc {
  margin-bottom: 5px;
  margin-left: 5px;
}
.bread {
  width: 100vw;
  padding-left: 10px;
  display: flex;
}
.toprouter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-icon-back {
  font-size: 25px;
}
.djs {
  flex: 1;
  display: flex;
  justify-content: right;
  margin-right: 10px;
}
.djs:hover {
  cursor: pointer;
}
@media (max-width: 550px) {
  .bread {
    width: 100vw;
    padding-left: 2px;
    display: flex;
  }
  .el-icon-back {
    font-size: 18px;
  }
  .djs {
    flex: 1;
    display: flex;
    justify-content: right;
    margin-right: 2px;
  }
}
</style>
