<template>
  <!-- v-if="urldata" -->

  <div>
    <Hander
      v-if="urldata"
      :pagetwoimg="pagetwoimg"
      :pagethreeing="pagethreeing"
    />
    <router-view></router-view>
    <Footer v-if="urldata" />
  </div>
</template>

<script>
import Base64 from "crypto-js/enc-base64";
import Hex from "crypto-js/enc-hex";
// import { loginout, getkeys, getnewtoken } from "./api/index";
import Hander from "./pages/handers.vue";
import Footer from "./pages/footers";
// import { getToken, removeToken, setToken } from "./utils/token";
// import smCrypto from "sm-crypto";
export default {
  components: {
    Hander,
    Footer,
  },
  mounted() {
    const currentURL = this.$route.fullPath;
    // console.log("currentURL", currentURL);
    if (currentURL != "/animation" && currentURL != "/") {
      this.urldata = true;
    } else {
      this.urldata = false;
    }
    // let datas = getToken();

    // if (datas != null && datas != undefined && datas != "") {
    //   this.$router.push("/home");
    //   this.urldata = true;
    // } else {
    //   this.urldata = false;
    // }
    // this.logindata();
  },
  watch: {
    $route: function (to, from) {
      if (to.fullPath != "/animation") {
        this.urldata = true;
      }
    },
    // 监听路由变化
  },
  created() {
    // 监听事件
    this.$bus.$on("pagetwoimgstate", (data) => {
      this.pagetwoimg = data;
    });
    this.$bus.$on("pagethreeimgstate", (data) => {
      this.pagethreeing = data;
    });
  },
  data() {
    return {
      donghuastate: false,
      urldata: false,
      pagetwoimg: false,
      pagethreeing: false,
      queryParams: {
        pageSize: 6,
        pageNum: 1,
      },
      intervalId: null,
    };
  },
  methods: {
    async logindata() {
      const sm2 = require("sm-crypto").sm2;
      // let Base64 = require("js-base64").Base64;
      let datas = getToken();
      // console.log("datas", datas);

      // let dataout = {
      //   browser: true,
      //   token: datas,
      // };
      // if (datas) {
      //   await this.$store.dispatch("userLogout", dataout);
      // }

      // 加密密码
      let keydata = null;
      await getkeys().then((item) => {
        keydata = item.data;
      });

      const plainText = "password";

      const password = Base64.stringify(
        Hex.parse("04" + sm2.doEncrypt(plainText, keydata, 1))
      );

      let data = {
        username: "admin",
        password: password,
      };
      if (datas == null) {
        this.donghuastate = true;
        await this.$store.dispatch("reqlogin", data);
        this.gettimedata();
        await this.$store.dispatch("getproductdata", this.queryParams);
        await this.$store.dispatch("getpagefivedata", this.queryParams);
      }
    },
    gettimedata() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      let dataid = sessionStorage.getItem("newfetnewtoken");
      let data = {
        refreshToken: dataid,
      };
      this.intervalId = setInterval(() => {
        getnewtoken(data).then((item) => {
          // sessionStorage.removeItem("newfetnewtoken");
          // console.log("item.data.result", item.data.result);
          // sessionStorage.setItem(
          //   "newfetnewtoken",
          //   item.data.result.refreshToken
          // );
          removeToken();
          setToken(item.data.result.accessToken); // 持久化存储 token
          this.gettimedata();
        });
      }, 120000);
      // 在页面关闭前清除 setInterval
      window.addEventListener("beforeUnload", () => {
        clearInterval(intervalId);
      });
    },
  },
};
</script>

<style scoped>
.hander {
  color: aliceblue;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  background: rgba(0, 0, 0, 0);
}
.footers {
  color: aliceblue;
  position: fixed;
  bottom: -5px;
  z-index: 10;
  width: 100%;
}
</style>
