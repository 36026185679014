import { reqlogin, loginout, geilist, getproduct, geindustry } from "../../api";
import { setToken, removeToken } from "../../utils/token";
import { getpageimg } from "@/api/index.js";
const state = {
  Cookie: "",
  Authorization: "",
  newsdat: {},
  productdata: [],
  pagefivedata: [],
  imgdata:[],
};
const mutations = {
  USERLOGIN(state, data) {
    sessionStorage.setItem("newfetnewtoken", data.refreshToken);
    // state.Cookie = data.refreshToken;
    // state.Authorization = data.accessToken;
  },
  CLEAR(state) {
    state.Cookie = "";
    state.Authorization = "";
    removeToken();
  },
  LISTDATA(state, data) {
    state.newsdat = data;
  },
  PRODUCTDATA(state, data) {
    state.productdata = data;
  },
  PAGEFIVE(state, data) {
    state.pagefivedata = data;
  },
  GETIMGDATA(state,data){
    state.imgdata=data
  }
};
const actions = {
  async reqlogin({ commit }, user) {
    let result = await reqlogin(user);
    if (result.status == 200) {
      commit("USERLOGIN", result.data.result);
      setToken(result.data.result.accessToken); // 持久化存储 token
      return "OK";
    } else {
      return Promise.reject(new Error("Login failed!"));
    }
  },
  async userLogout({ commit }, data) {
    let result = await loginout(data);
    if (result.status === 200) {
      commit("CLEAR");
      return "ok";
    } else {
      return Promise.reject(new Error("Logging out failed!"));
    }
  },
  async geilistdata({ commit }, data) {
    let result = await geilist(data);
    if (result.status === 200) {
      commit("LISTDATA", result.data);
      return "ok";
    } else {
      return Promise.reject(new Error("Logging out failed!"));
    }
  },
  async getproductdata({ commit }, data) {
    let result = await getproduct(data);
    if (result.status === 200) {
      commit("PRODUCTDATA", result.data.content);
      return "ok";
    } else {
      return Promise.reject(new Error("Logging out failed!"));
    }
  },
  async getpagefivedata({ commit }, data) {
    let result = await geindustry(data);
    if (result.status === 200) {
      commit("PAGEFIVE", result.data.content);
      return "ok";
    } else {
      return Promise.reject(new Error("Logging out failed!"));
    }
  },
  async getpageimg({ commit }, data) {
    let result = await getpageimg();
    if (result.status === 200) {
      commit("GETIMGDATA", result.data);
      return "ok";
    } else {
      return Promise.reject(new Error("请检查网络！！！"));
    }
  },
};
const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
