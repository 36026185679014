<template>
  <div class="hander">
    <div
      class="hander-center"
      :style="{
        background: imgstate ? ' rgba(0, 0, 0, 0)' : 'rgb(255, 255, 255)',
        color: imgstate ? 'aliceblue' : 'black',
      }"
      @mouseover="showhanderdown()"
      @mouseout="showhanderout()"
    >
      <div class="hander-top">
        <div class="hantop-left">
          <div class="hantop-left-search">
            <i class="icon" @click="toggleSearch">
              <i v-if="!showSearch" class="el-icon-search"></i>
              <i
                v-else-if="showSearch"
                style="margin-right: 10px"
                class="el-icon-close"
              ></i>
            </i>
            <div class="chanpin" v-if="!showSearch">搜索</div>
            <el-select
              v-if="showSearch"
              v-model="value"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="hantop-left-product">
            <div style="margin-right: 1.2rem">
              <i class="el-icon-menu"></i>
            </div>
            <div class="chanpin">产品</div>
          </div> -->
        </div>
        <div class="hantop-middle" v-if="imgstate">
          <img class="my-images" src="./home/icons/1.png" alt="" />
        </div>
        <div class="hantop-middle" v-else>
          <img class="my-images" src="./home/icons/2.png" alt="" />
        </div>
        <div class="hantop-right">
          <div class="caidan">菜单</div>
          <div style="margin-left: 1.2rem">
            <i class="el-icon-s-operation"></i>
          </div>
        </div>
      </div>
      <div class="hander-bottom">
        <div class="itemlist">
          <ul class="horizontal-list">
            <li
              v-for="(item, index) in itemlist"
              :key="index"
              @click="activelist(index)"
              @mouseover="showDropdown(item.id)"
              @mouseout="showDropout()"
            >
              <div class="showdownitem" @click="purouter(item.name)">
                {{ item.name }}
                <i v-if="item.name !== '首页'" class="el-icon-arrow-down"></i>
                <div
                  :class="
                    activeIndex == index ? 'progress-bars' : 'progress-bar'
                  "
                ></div>
              </div>
            </li>
          </ul>
        </div>

        <div class="buyingproducts">
          <el-dropdown>
            <span class="el-dropdown-link">
              购买渠道
              <i style="font-size: 1.6rem" class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>紫云天猫旗舰店</el-dropdown-item>
              <el-dropdown-item>紫云京东旗舰店</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <transition name="height-fade">
        <div
          class="itemlistdata"
          :style="{ height: targetDivHeight + 'px' }"
          @mouseenter="showDropdowns(1)"
          @mouseleave="showDropouts()"
        >
          <div class="itemlistdatali">
            <div class="imgsize">
              <img class="my-image" src="./home/icons/7.jpeg" alt="" />
            </div>

            <div class="itemlistname">图片</div>
          </div>
        </div>
      </transition>
    </div>
    <div class="seekadvice">
      <el-tooltip class="item" effect="dark" content="在线咨询" placement="top">
        <i
          @click="seekadvice"
          style="font-size: 32px"
          class="el-icon-service"
        ></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
export default {
  props: ["pagetwoimg", "pagethreeing"],
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "产品一",
        },
        {
          value: "选项2",
          label: "产品二",
        },
        {
          value: "选项3",
          label: "产品三",
        },
      ],
      showSearch: false,
      activestate: -1,
      pageonetate: false,
      imgstate: true,
      targetDivHeight: 0, // 初始高度
      showlist: -1,
      showcenter: -1,
      centerstate: -1,
      itemlist: [
        {
          name: "首页",
          id: 1,
          imgs: [],
        },
        {
          name: "关于紫云",
          id: 2,
          imgs: [
            {
              imgurl: "./icons/7.jpeg",
              name: "家用产品",
            },
          ],
        },
        {
          name: "业务领域",
          id: 3,
          imgs: [],
        },
        {
          name: "产品中心",
          id: 4,
          imgs: [],
        },
        {
          name: "媒体中心",
          id: 5,
          imgs: [],
        },
        {
          name: "联系我们",
          id: 6,
          imgs: [],
        },
      ],
      activeIndex: -1,
    };
  },
  watch: {
    pagethreeing(val, old) {
      if (val) {
        this.imgstate = true;
      } else {
        this.imgstate = false;
      }
    },
    pagetwoimg(val, old) {
      if (val) {
        this.imgstate = false;
        this.pageonetate = true;
      } else {
        this.pageonetate = false;
        this.imgstate = true;
      }
    },
  },
  methods: {
    seekadvice() {
      this.$router.push("/contact");
    },
    toggleSearch() {
      this.showSearch = !this.showSearch; // 切换搜索框的显示与隐藏
    },
    purouter(data) {
      if (data == "关于紫云") {
        this.$router.push("/introduction");
      } else if (data == "首页") {
        this.$router.push("/home");
      } else if (data == "业务领域") {
        this.$router.push("/business");
      } else if (data == "媒体中心") {
        this.$router.push("/mediacenter");
      } else if (data == "产品中心") {
        this.$router.push("/product");
      } else if (data == "联系我们") {
        this.$router.push("/contact");
      }
    },
    showhanderdown() {
      if (this.pagetwoimg == false) {
        this.imgstate = false;
      }
    },
    showhanderout() {
      if (this.pagetwoimg == false) {
        this.imgstate = true;
      }
    },
    showDropdown: throttle(function (index) {
      this.activeIndex = index - 1;
      this.showlist = index;
      this.showcente = index;
      this.targetDivHeight = 140;
    }),
    showDropout: throttle(function (index) {
      if (this.activestate == -1) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = this.activestate;
      }
      this.showlist = -1;
      if (this.showlist == -1 && this.showcente == -1) {
        this.targetDivHeight = 0;
      } else if (this.showlist == -1 && this.centerstate == -1) {
        this.targetDivHeight = 0;
      }
    }),
    showDropdowns: throttle(function (index) {
      if (index == 1) {
        this.activeIndex = this.showcente - 1;
      }
      this.centerstate = index;
      this.targetDivHeight = 140;
    }),
    showDropouts: throttle(function () {
      if (this.activestate == -1) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = this.activestate;
      }
      this.showcente = -1;
      this.centerstate = -1;
      this.targetDivHeight = 0;
    }),

    activelist(data) {
      this.activeIndex = data;
      this.activestate = data;
    },
  },
};
</script>

<style scoped>
::v-deep .el-input--suffix .el-input__inner {
  background: rgba(0, 0, 0, 0);
}
.el-icon-s-operation {
  font-size: 2.4rem;
}
.el-icon-menu {
  font-size: 2.4rem;
}
.el-icon-search {
  font-size: 2.4rem;
}
.el-icon-close {
  margin-top: 5px;
  font-size: 2.4rem;
}
.caidan {
  font-size: 1.6rem;
}
.chanpin {
  font-size: 1.6rem;
  margin-left: 10px;
}
.seekadvice {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 50px;
  right: 50px;
}
.el-icon-service:hover {
  color: #409eff;
}
.el-dropdown-link {
  cursor: pointer;
  font-size: 1.6rem;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.itemlistdata {
  position: fixed;
  top: 138px;
  width: 100%;
  padding: 0 30px;
  background-color: #fff;
  overflow: hidden;
  transition: height 0.5s; /* 过渡效果的持续时间 */
}
.height-fade-enter-active,
.height-fade-leave-active {
  transition: height 0.5s;
}

.height-fade-enter, .height-fade-leave-to /* .height-fade-leave-active 在版本 2.1.8 中新增 */ {
  height: 0;
}
.hander-bottom {
  height: 6.6rem;
  padding: 0 3rem;
  display: flex;
  font-size: 1.6rem;
}
.my-images {
  height: 7rem;
}
.imgsize {
  width: 18rem;
  height: 10rem;
  overflow: hidden;
}
.my-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
}
.my-image:hover {
  transform: scale(1.2);
}
.itemlistdatali {
  margin-top: 1rem;
  width: 18rem;
  height: 100%;
}
.itemlistname {
  height: 100%;
  text-align: center;
}
.hander-center {
  height: 13.8rem;
}
.hander-center:hover {
  color: black;
  background: rgb(255, 255, 255);
}
.horizontal-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.horizontal-list li {
  margin-right: 1rem;
  line-height: 4.6rem;
}
li {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
}

/* 设置进度条的样式 */
.progress-bar {
  position: relative;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0.4rem;
  background-color: #6cccdc;
  transition: width 0.5s ease-in-out;
}
.progress-bars {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.4rem;
  background-color: #6cccdc;
  transition: width 0.5s ease-in-out;
}
/* 点击后的样式*/
li:visited .progress-bar {
  width: 100%;
}
li:hover .progress-bar {
  width: 100%;
}
.hander {
  display: flex;
  flex-direction: column;
}
.hander-top {
  height: 7.1rem;
  border-bottom: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
}
.hantop-left {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-basis: 10%; */
  width: 21rem;
}
.hantop-left-search {
  display: flex;
  width: 100%;
}
.hantop-left-product {
  flex-grow: 1;
  display: flex;
}
.hantop-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-basis: 80%; */
  flex-grow: 1;
}
.hantop-right {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 21rem;
}
.search {
  flex-basis: 30%;
}
.itemlist {
  flex-basis: 60%;
}
.buyingproducts {
  display: flex;
  flex-basis: 40%;
  align-items: center;
  justify-content: right;
}
@media (max-width: 950px) {
  .el-icon-s-operation {
    font-size: 2rem;
  }
  .my-images {
    height: 5rem;
  }

  .caidan {
    font-size: 1.5rem;
  }
  .chanpin {
    font-size: 1.5rem;
  }
  .el-icon-menu {
    font-size: 2rem;
  }
  .el-icon-search {
    font-size: 2rem;
  }
  .el-icon-close {
    font-size: 2rem;
  }
  .hander-top {
    padding: 0 1rem;
  }
  .hander-bottom {
    padding: 0 1rem;
  }
  .horizontal-list {
    width: 34rem;
  }
  .horizontal-list li {
    font-size: 1rem;
    padding: 0.1rem;
  }
  .el-dropdown-link {
    font-size: 1rem;
    margin-bottom: 18px;
  }
  .el-icon-arrow-down {
    display: none;
  }
}
</style>
