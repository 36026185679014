<template>
  <div class="pagethree">
    <div class="carouseltitle">
      紫云一站式净水服务
      <div style="color: #7e8ba5"><b>你的安全水卫士</b></div>
      <div class="centerurl" @click="goproduct">
        <el-button
          class="lefticon"
          icon="el-icon-right"
          style="background: #6cccdc"
          circle
        ></el-button>
        了解更多
      </div>
    </div>
    <div class="carouselcenter">
      <ul class="horizontal-list">
        <li
          v-for="(item, index) in itemlist"
          :key="index"
          @click="activelists(index)"
        >
          <div class="showdownitem">
            {{ item }}
            <div
              :class="activeIndex == index ? 'progress-bars' : 'progress-bar'"
            ></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="carousel">
      <carousel-3d
        :autoplay="true"
        :autoplayTimeout="2000"
        @mouseover="stopCarousel"
        @mouseout="startCarousel"
        space="300"
        :width="imgwidth"
        :height="imgheight"
        @before-slide-change="handleSlideChange"
      >
        <slide :index="0">
          <img class="myimg" v-lazy="cp1" />
        </slide>
        <slide :index="1">
          <img class="myimg" v-lazy="cp2" />
        </slide>
        <slide :index="2">
          <img class="myimg" v-lazy="cp3" />
        </slide>
        <slide :index="3">
          <img class="myimg" v-lazy="cp5" />
        </slide>
        <slide :index="4">
          <img class="myimg" v-lazy="cp4" />
        </slide>
      </carousel-3d>
      <template v-for="(item, index) in introductiondata">
        <div class="introduction" v-if="introductionnum == index" :key="index">
          <div class="introductiontitle">{{ item.name }}</div>
          <div class="introductioncent">
            {{ item.center }}
          </div>
          <div class="introductionmoney">¥ {{ item.money }} 起</div>
          <div class="introductiontn">
            <div class="introductionbtnleft" @click="detailsdata(index)">
              了解更多 <i class="el-icon-arrow-right"></i>
            </div>
            <div class="introductionbtnright" @click="buypublic(item.url)">
              立即购买
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="progress">
      <Progress />
      <div class="progressitem">滑动</div>
    </div>
  </div>
</template>

<script>
import { getproduct } from "../../api/index";
import Progress from "../component/progress";
import store from "../../store";
export default {
  props: ["pagethreeing"],
  components: {
    Progress,
  },
  data() {
    return {
      cp1: "./textures/cp1.jpeg",
      cp2: "./textures/cp2.jpeg",
      cp3: "./textures/cp3.jpeg",
      cp5: "./textures/cp4.jpeg",
      cp4: "./textures/cp5.jpeg",
      imgdata: [],
      queryParams: {
        pageSize: 5,
        pageNum: 1,
      },
      imgheight: 270,
      imgwidth: 360,
      screenWidth: null,
      introductionnum: 0,
      introductiondata: [
        {
          name: "全自动水质安全保障机",
          center: "地表水废水处理反渗透/应急饮用水处理",
          money: "200000.00",
          url: "https://detail.1688.com/offer/683854777911.html?spm=a2615.12330364.wp_offercolumnnew.0",
        },
        {
          name: "一体化智慧供水泵站",
          center: "一体化二次供水智慧设备/二次供水自动清洁",
          money: "770000.00",
          url: "https://detail.1688.com/offer/743550494031.html?spm=a2615.12330364.wp_offercolumnnew.0",
        },
        {
          name: "直饮净水机",
          center: "商务工厂学校办公直饮净水/可移动大流量RO反渗透工厂",
          money: "39000.00",
          url: "https://detail.1688.com/offer/683787569938.html?spm=a2615.12330364.wp_offercolumnnew.0",
        },
        {
          name: "悦洲Ⅴ",
          center: "一体化净水设备自动净水器/别墅供水自动化中央水处理设备",
          money: "150000.00",
          url: "https://detail.1688.com/offer/681236198904.html?spm=a2615.12330364.wp_offercolumnnew.0",
        },
        {
          name: "悦洲V全自动一体化净水设备",
          center: "处理高浊度水/中、小型水厂站专用",
          money: "100000.00",
          url: "https://detail.1688.com/offer/743550494031.html?spm=0.0.wp_pc_common_header_topoffer_undefined.0",
        },
      ],
      activeIndex: 0,
      itemlist: ["工业产品"],
      carouselImages: [
        // require("../../img/earth.jpeg"),
        // require("../../img/2.jpeg"),
        // require("../../img/30.png"),
        // require("../../img/banner1.jpeg"),
      ],
      isPlaying: true, // 记录轮播是否正在播放的状态
    };
  },

  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },

  watch: {
    pagethreeing(val, old) {
      this.imgdata = [];
      if (val == false) {
        let newdatas = _.cloneDeep(this.$store.state.user.productdata);
        if (newdatas.length == 0) {
          this.getpublist();
        } else {
          // newdatas.forEach((item) => {
          // this.introductiondata.push({
          //   name: item.title,
          //   center: item.tagNames[0],
          //   money: item.customs.price,
          //   url: item.description,
          // });
          // });
        }
      }
    },
    screenWidth: function (n, o) {
      if (n < 660) {
        this.imgwidth = 240;
        this.imgheight = 180;
      } else {
        this.imgwidth = 360;
        this.imgheight = 270;
      }
    },
  },

  methods: {
    detailsdata(data) {
      if (data == 0) {
        this.$router.push("/product/detailsthree");
      } else if (data == 1) {
        this.$router.push("/product/details");
      } else if (data == 2) {
        this.$router.push("/product/detailsfour");
      } else if (data == 3) {
        this.$router.push("/product/detailstwo");
      } else if (data == 4) {
        this.$router.push("/product/detailsfive");
      }
    },
    getpublist() {
      getproduct(this.queryParams).then((data) => {
        let newdatas = _.cloneDeep(data.data.content);
        // newdatas.forEach((item) => {
        // this.introductiondata.push({
        //   name: item.title,
        //   center: item.tagNames[0],
        //   money: item.customs.price,
        //   url: item.description,
        // });
        // });
      });
    },
    buypublic(data) {
      window.open(data);
    },
    handleSlideChange(index) {
      this.introductionnum = index;
    },
    goproduct() {
      this.$router.push("/product");
    },
    showDropdown(data) {
      this.activeIndex = data;
    },
    showDropout() {
      this.activeIndex = -1;
    },
    activelists(data) {
      this.activeIndex = data;
    },
    stopCarousel() {
      this.isPlaying = false; // 停止轮播
    },
    startCarousel() {
      this.isPlaying = true; // 开始轮播
    },
  },
};
</script>

<style scoped>
.el-icon-arrow-right {
  margin-top: 3px;
}
.introductionbtnright {
  font-size: 18px;
  display: flex;
  color: #000;
}
.introductionbtnleft {
  padding: 0 1vw;
  font-size: 18px;
  display: flex;
  justify-content: center;
  color: #000;
}
.introductiontn {
  display: flex;
  padding-bottom: 30px;
}
.introductionmoney {
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 8px;
  color: #000;
}
.introductioncent {
  font-size: 18px;
  padding: 0 0 8px;
  color: #575757;
}
.introductiontitle {
  font-size: 24px;
  padding: 0 0 8px;
  font-weight: bold;
  color: #000;
}

.centerurl {
  font-size: 15px;
  line-height: 33px;

  margin-top: 37px;
}
.centerurl:hover {
  cursor: pointer;
}
.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  margin-top: 30px;
}
.progress {
  height: 60px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.progressitem {
  font-size: 14px;
  color: #999;
}
.showdownitem {
  font-size: 20px;
  color: #2c3441;
}
.myimg {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.pagethree {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* background: url("./icons/3.png"); */
  background-size: cover;
}
.carouseltitle {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 100%;
  font-size: 30px;
  z-index: 2;
  color: #333333;
  line-height: 1.2;
  font-weight: bold;
}
.carouselcenter {
  z-index: 11;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.carousel {
  position: absolute;
  top: 30%;
  width: 100%;
  overflow: hidden;
}
.horizontal-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.horizontal-list li {
  margin-right: 10px;
  line-height: 46px;
}
li {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
}

/* 设置进度条的样式 */
.progress-bar {
  position: relative;
  bottom: 0;
  left: 0;
  width: 0;
  height: 4px;
  background-color: #6cccdc;
  transition: width 0.5s ease-in-out;
}
.progress-bars {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #6cccdc;
  transition: width 0.5s ease-in-out;
}
li:visited .progress-bar {
  width: 100%;
}
li:hover .progress-bar {
  width: 100%;
}
@media (max-width: 600px) {
  /* ::v-deep .carousel-3d-slide {
    width: 0px !important;
    height: 180px !important;
  } */
  .introductionbtnleft {
    font-size: 16px;
  }
  .introductionbtnright {
    font-size: 16px;
  }
  .introductiontitle {
    font-size: 16px;
    padding: 0 0 4px;
  }
  .introductionmoney {
    font-size: 16px;
    padding: 0 0 4px;
  }
  .introductioncent {
    font-size: 14px;
    padding: 0 0 4px;
  }
  .centerurl {
    line-height: 20px;

    margin-top: 10px;
  }
  .carouselcenter {
    top: 30%;
  }
  .carouseltitle {
    top: 22%;
    font-size: 1.6rem;
  }
  .horizontal-list {
    /* width: 27rem; */
  }
  .showdownitem {
    font-size: 1.5rem;
  }
  .carousel-item img {
    width: 200px;
  }
  .carousel {
    position: absolute;
    top: 37%;
    width: 100%;
    overflow: hidden;
  }
}
</style>
