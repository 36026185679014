<template>
  <div class="donghua">
    <div id="pureFullPage">
      <!-- <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div> -->
    </div>
    <div class="transtime" v-if="audiostates">
      <Shuzi :starts="0" :ends="30" :durations="31" />
    </div>
    <div class="" v-if="audiostates">
      <transition name="fade" mode="out-in" v-if="pagenums == 1">
        <div class="page13d">
          <div class="page13dtitle">紫云集团1993年成立，距今30年历史。</div>
          <div class="page13dcenter">不惧风雨，我们砥砺前行。</div>
        </div>
      </transition>
      <transition name="fade" mode="out-in" v-if="pagenums == 2">
        <div class="page23d">
          <div class="page13dtitle">科技推动公司发展。</div>
          <div class="page13dcenter">
            我们秉持豪迈志气，铮铮骨气，必胜底气，奋力拼搏，以科技推动公司发展。
          </div>
        </div>
      </transition>
      <transition name="fade" mode="out-in" v-if="pagenums == 3">
        <div class="page33d">
          <div class="page13dtitle">创新提高公司竞争力。</div>
          <div class="page13dcenter">
            我们坚持理论创新，制度创新，工作创新和方法创新。
          </div>
        </div>
      </transition>
      <transition name="fade" mode="out-in" v-if="pagenums == 4">
        <div class="page43d">
          <div class="page13dtitle">以人才为公司发展的重要驱动力。</div>
          <div class="page13dcenter">
            我们重视人才的发掘和培养，提供多元化的职业发展路径，鼓励员工追求卓越，为公司的持续发展提供源源不断的动力。
          </div>
        </div>
      </transition>
      <transition name="fade" mode="out-in" v-if="pagenums == 5">
        <div class="page53d">
          <div class="page13dtitle">三十年风雨历程。</div>
          <div class="page13dcenter">
            这是新的里程碑，也是新的起点，公司将继续以创新为动力，引领行业发展，书写更加辉煌的篇章，迈向更高峰。
          </div>
        </div>
      </transition>
    </div>
    <div id="mouse">
      <div id="wheel"></div>
    </div>
    <!-- v-if="audiostates" -->
    <div>
      <div class="gohome" @click="gohome">
        <img src="./shouye.png" alt="webp image" />
      </div>
      <div v-if="!audiostate" class="playaudio" @click="playaudio">
        <img src="./1.png" alt="webp image" />
      </div>
      <div v-if="audiostate" class="playaudio" @click="pauseAudio">
        <img src="./2.png" alt="webp image" />
      </div>
      <audio ref="audioRef" src="./bg.mp3" controls @ended="onEnded"></audio>
    </div>
  </div>
</template>

<script>
// import Home from "../home/home";
import {
  setupScene,
  changeModel,
  firstAnimation,
  pauseAnimation,
  clearAroundParticles,
  clearMainParticles,
  clearAnimation,
} from "./3dview/scene";
import PureFullPage from "pure-full-page";
import "pure-full-page/lib/pureFullPage.min.css";
import "./index.css";
import { log } from "three";
import { throttle } from "lodash";
import Shuzi from "./shuzi/index";
import djsstate from "./djsstate";
export default {
  components: {
    Shuzi,
  },
  data() {
    return {
      pagenums: 0,
      audiostate: false,
      audiostates: false,
      isActive: true,
      index: 0,
      scrollExecuted: false,
      timerId: null,
      screenWidth: null,
      pagefont: 600,
    };
  },
  watch: {
    screenWidth: function (n, o) {
      if (n < 600) {
        djsstate.$data.pagesix = 50;
      } else {
        djsstate.$data.pagesix = 100;
      }
    },
    index(val, old) {
      console.log("val", val);
    },
  },
  beforeDestroy() {
    pauseAnimation();
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null; // 清空定时器ID，确保下次安全地重新创建定时器
    }
    const viewElement = document.getElementById("view");

    viewElement.parentNode.removeChild(viewElement);

    // console.log("1");
    window.onresize = null;
  },
  mounted() {
    setupScene();
    // 显示第一个动画
    this.donghua();
    this.screenWidth = window.innerWidth;

    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    gohome() {
      this.$router.push("/home");
      // window.location.reload();
    },
    donghua() {
      firstAnimation().then(() => {
        this.audiostates = true;
        if (this.audiostates == true) {
          this.timerId = setInterval(() => {
            if (this.index < 6) {
              this.index++;
              changeModel(this.index);
              this.pagenums = this.index;
            } else {
              clearInterval(this.timerId);
              this.gohome();
            }
          }, 5000);
        }

        // 显示dom;
        // document.querySelector("#pureFullPage").style.opacity = "1";
        // document.querySelector("#mouse").style.opacity = "1";
        // let data = 0;
        // 创建全屏滚动容器

        // new PureFullPage({
        //   definePages() {
        //     const index = Math.abs(this.currentPosition / this.viewHeight);
        //     console.log(
        //       "this.currentPosition",
        //       this.currentPosition,
        //       this.viewHeight
        //     );
        //     console.log("index", index);
        //     // this.changenums(index);
        //     // 切换模型
        //     changeModel(index);
        //   },
        // }).init();
        // console.log("data", data);
      });
    },

    changenums(data) {},
    onEnded() {
      // 循环播放音频
      this.$refs.audioRef.play();
    },
    playaudio() {
      this.$refs.audioRef.currentTime = 3;
      this.$refs.audioRef.play();
      this.audiostate = true;
    },
    pauseAudio() {
      this.$refs.audioRef.pause();
      this.audiostate = false;
    },
  },
};
</script>

<style scoped>
.donghua {
  margin: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
/* CSS样式中的过渡类定义 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active 在Vue2.x 中为 .fade-leave-to */ {
  opacity: 0;
}

/* 设置动画class为page13d的从模糊到清晰 */
.page13d {
  animation: fadeEffect 3s;
}
.page23d {
  animation: fadeEffect 3s;
}
.page33d {
  animation: fadeEffect 3s;
}
.page43d {
  animation: fadeEffect 3s;
}
.page53d {
  animation: fadeEffect 3s;
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#pureFullPage,
#mouse {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.page13dtitle {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 5px;
}
.page13dcenter {
  line-height: 30px;
  font-size: 24px;
}
.transtime {
  text-align: center;
  position: absolute;
  /* height: 20vh; */
  /* width: 30vw; */
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "华文宋体", serif;
  z-index: 1;
}
.page33d {
  text-align: center;
  position: absolute;
  height: 20vh;
  width: 30vw;
  top: 47%;
  left: 65%;

  color: #fff;
  font-family: "华文宋体", serif;
  z-index: 1;
}
.page53d {
  text-align: center;
  position: absolute;
  height: 20vh;
  width: 40vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "华文宋体", serif;
  z-index: 1;
}

.page13d {
  text-align: center;
  position: absolute;
  height: 20vh;
  width: 30vw;
  top: 47%;
  left: 65%;

  color: #fff;
  font-family: "华文宋体", serif;
  /* background: #fff; */
  z-index: 1;
  /* filter: blur(5px); */
  /* transition: filter 5s; */
}
.page23d {
  text-align: center;
  position: absolute;
  height: 20vh;
  width: 30vw;
  top: 47%;
  left: 10%;

  color: #fff;
  font-family: "华文宋体", serif;
  /* background: #fff; */
  z-index: 1;
}
.page43d {
  text-align: center;
  position: absolute;
  height: 20vh;
  width: 30vw;
  top: 47%;
  left: 10%;

  color: #fff;
  font-family: "华文宋体", serif;
  /* background: #fff; */
  z-index: 1;
}
.gohome {
  position: absolute;
  top: 20px;
  right: 50px;
  background-size: cover;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.gohome:hover {
  cursor: pointer;
}
.playaudio {
  position: absolute;
  top: 20px;
  right: 20px;
  background-size: cover;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.playaudio:hover {
  cursor: pointer;
}
.playaudio img {
  width: 100%;
  height: 100%;
  background-size: cover;
}
#mouse {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 40px;
  height: 76px;
  border: 2px solid #a3a3a7;
  border-radius: 19px;
  z-index: 1;
}

#wheel {
  width: 8px;
  height: 16px;
  position: absolute;
  top: 1px;
  left: 16px;
  background-color: #c3c3c3;
  border-radius: 6px;
  animation: mousewheel 3s infinite;
}
@media (max-width: 600px) {
  .page33d {
    text-align: center;
    position: absolute;
    width: 70vw;
    height: 20vh;
    top: 80%;
    left: 15%;
    margin: auto;
  }
  .page43d {
    text-align: center;
    position: absolute;
    width: 70vw;
    height: 20vh;
    top: 80%;
    left: 15%;
    margin: auto;
  }
  .page53d {
    width: 70vw;
    height: 20vh;
  }
  .page23d {
    text-align: center;
    position: absolute;
    width: 70vw;
    height: 20vh;
    top: 80%;
    left: 15%;
    margin: auto;
  }
  .page13d {
    text-align: center;
    position: absolute;
    width: 70vw;
    height: 20vh;
    top: 80%;
    left: 15%;
    margin: auto;
  }
  .page13dtitle {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .page13dcenter {
    line-height: 20px;
    font-size: 16px;
  }
}
@keyframes mousewheel {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    transform: translateY(60px);
    opacity: 0;
  }
}
</style>
