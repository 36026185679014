var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hander"},[_c('div',{staticClass:"hander-center",style:({
      background:
        _vm.imgstate && _vm.urldata == '/home'
          ? ' rgba(0, 0, 0, 0)'
          : 'rgb(255, 255, 255)',
      color: _vm.imgstate && _vm.urldata == '/home' ? 'aliceblue' : 'black',
    })},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"handerimg",attrs:{"src":require("../img/bacimg.png"),"alt":""}}),_c('div',{staticClass:"div-content",on:{"click":_vm.gohome}},[_vm._m(0),_c('div',{staticClass:"contentcenter"},[_vm._v("西安紫云环保科技集团有限公司")])])]),_c('div',{staticClass:"hander-bottom"},[_c('div',{staticClass:"itemlist"},[_c('ul',{staticClass:"horizontal-list"},_vm._l((_vm.itemlist),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.activelist(index)}}},[_c('div',{staticClass:"showdownitem",on:{"click":function($event){return _vm.purouter(item.name)}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{class:_vm.activeIndex == index ? 'progress-bars' : 'progress-bar'})])])}),0)]),_c('div',{staticClass:"buyingproducts"},[_c('el-dropdown',[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" 购买渠道 "),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"font-size":"1.6rem"}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.firstbuy.apply(null, arguments)}}},[_vm._v("紫云1688旗舰店")])],1)],1)],1)]),(_vm.breadstate)?_c('div',{staticClass:"breadpage"},[_c('Breadcrumbs',{attrs:{"datasurl":_vm.datasurl}})],1):_vm._e()]),_c('div',{staticClass:"seekadvice",on:{"click":_vm.seekadvice}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"在线咨询","placement":"top"}},[_c('i',{staticClass:"el-icon-service",staticStyle:{"font-size":"32px"}})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenttitle"},[_c('img',{staticClass:"img",attrs:{"src":require("./mediacenter/style/logobig.png"),"alt":""}})])
}]

export { render, staticRenderFns }