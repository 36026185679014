<template>
  <div class="main" ref="scrollContainer">
    <!-- <Hander :pagetwoimg="pagetwoimg" :pagethreeing="pagethreeing" /> -->
    <section class="pages1">
      <Pageone :donghuastate="donghuastate" />
    </section>
    <section ref="secondScreen">
      <Pagetwo :pagetwostate="pagetwostate" :pagetwoimg="pagetwoimg" />
    </section>
    <section ref="threeScreen"><Pagefore /></section>
    <section><Pagethree :pagethreeing="pagethreeing" /></section>
    <section><Pagefive /></section>
    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script>
// import Handerfooter from "../homefooter";
import Hander from "../hander";
import Pageone from "./page1.vue";
import Pagetwo from "./page2.vue";
import Pagethree from "./page3.vue";
import Pagefore from "./page4.vue";
import Pagefive from "./page5.vue";
import Footer from "../footer";
export default {
  data() {
    return {
      pagethreeing: false,
      pagetwoimg: false,
      pagetwostate: false,
      donghuastate: false,
      data: [
        // require("../../img/earth.jpeg"),
        // require("../../img/2.jpeg"),
        // require("../../img/30.png"),
        // require("../../img/banner1.jpeg"),
      ],
    };
  },
  components: {
    Pagefore,
    Hander,
    Footer,
    Pageone,
    Pagetwo,
    Pagethree,
    Pagefive,
  },
  mounted() {
    const scrollContainer = this.$refs.scrollContainer;

    scrollContainer.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    const scrollContainer = this.$refs.scrollContainer;

    scrollContainer.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const threeScreen = this.$refs.threeScreen.getBoundingClientRect().top;
      const secondScreen = this.$refs.secondScreen;
      let secondScreenOffset = secondScreen.getBoundingClientRect().top;
      if (secondScreenOffset <= 0) {
        this.donghuastate = true;
      } else {
        this.donghuastate = false;
      }
      if (threeScreen <= 200) {
        if (threeScreen > -300) {
          this.pagethreeing = true;
          this.pagetwoimg = false;
          this.$bus.$emit("pagetwoimgstate", this.pagetwoimg);
          this.$bus.$emit("pagethreeimgstate", this.pagethreeing);
        } else {
          this.pagethreeing = false;
          this.pagetwoimg = true;
          this.$bus.$emit("pagetwoimgstate", this.pagetwoimg);
          this.$bus.$emit("pagethreeimgstate", this.pagethreeing);
        }
      } else {
        // if (secondScreenOffset <= 200) {
        //   this.pagetwoimg = true;
        //   this.$bus.$emit("pagetwoimgstate", this.pagetwoimg);
        // } else {
        //   this.pagetwoimg = false;
        //   this.$bus.$emit("pagetwoimgstate", this.pagetwoimg);
        // }
        if (0 <= secondScreenOffset && secondScreenOffset <= 150) {
          this.pagetwostate = true;
        } else {
          this.pagetwostate = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.imgdata {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.pages1 {
  width: 100%;
  height: 100%;
  /* background: url("./img/earth.jpeg");
  background-size: cover; */
}
.hander {
  color: aliceblue;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  /* height: 278px; */
  background: rgba(0, 0, 0, 0);
}

html,
body {
  margin: 0;
  padding: 0;

  scroll-behavior: smooth;
}

.main {
  overflow: scroll; /* overflow 为 scroll，来把滚动条设置到 main 元素上 */
  height: 100vh;
  scroll-snap-type: y mandatory; /* scroll-snap-type 需要两个值，第一个值为滚动贴合的方向，y 表示纵向滚动贴合，第二个值为贴合方式，mandatory 表示强制滚动，用户只要一滚动鼠标，下一屏内容就直接滚动上来进行贴合。 */
  /* scroll-padding: 80px; */
  scroll-behavior: smooth;
}

.main::-webkit-scrollbar {
  display: none;
}

/* h1 {
  height: 80px;
  position: sticky;
  top: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
} */

/* 每个 section 都设置为占满全屏： */
section {
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  display: grid;
  /* padding: 71px 0 30px 0; */
  /* place-items: center; */
  scroll-snap-align: start;
}

footer {
  display: flex;
  justify-content: space-between;
  scroll-snap-align: start;
}

ul {
  padding: 0;
  list-style: none;
}
</style>
