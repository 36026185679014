<template>
  <div class="footers">
    <img class="footerimg" src="../img/footer.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footerimg {
  height: 40px;
  width: 100vw;
  background-size: cover;
}
</style>