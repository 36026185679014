<template>
  <div class="pagefore">
    <img class="bacimg" v-lazy="page1" />
    <div class="pageforetitle">
      <div class="titlename">业务领域</div>
      <div class="titlecenter">紫云净水带你展望未来水厂</div>
      <div class="titlecenter">多膜法+装备化+物联网+智慧化</div>
      <div class="centerurl" @click="gobisiness">
        <el-button
          class="lefticon"
          icon="el-icon-right"
          style="background: #6cccdc"
          circle
        ></el-button>
        了解更多
      </div>
      <div class="lefticoncenter">
        <Suspension />
      </div>
    </div>
    <div class="progress">
      <Progress />
      <div class="progressitem">滑动</div>
    </div>
  </div>
</template>

<script>
import Progress from "../component/progress";
import Suspension from "./suspension";
export default {
  components: {
    Suspension,
    Progress,
  },
  data() {
    return {
      page1: "textures/2.jpeg",
    };
  },
  mounted() {
    let screenWidths = document.body.clientWidth;
    if (screenWidths < 550) {
      this.page1 = "textures/page13s.jpeg";
    }
  },
  methods: {
    gobisiness() {
      this.$router.push("/business");
    },
  },
};
</script>

<style scoped>
.bacimg {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.progress {
  height: 60px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.progressitem {
  font-size: 14px;
  color: #999;
}
.lefticoncenter {
  margin-top: 37px;
}
.centerurl {
  font-size: 15px;
  line-height: 33px;
  color: #fff;
  margin-top: 37px;
}
.centerurl:hover {
  cursor: pointer;
}
.pagefore {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background: url("./icons/3.png"); */
  /* background: url("../../img/2.jpeg") no-repeat center center fixed; */

  /* background-size: cover; */
}
.pageforetitle {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.titlename {
  color: #fff;
  margin-bottom: 37px;
  font-size: 16px;
  line-height: 1;
}
.titlecenter {
  font-size: 30px;
}
@media (max-width: 950px) {
  .titlecenter {
    font-size: 18px;
  }
}
</style>
